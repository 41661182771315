<template>
  <div>
    <div class="mb-3" v-if="hasAggregatedData">
      <font-awesome-icon icon="money-check-alt" />
      <span class="ml-2 mr-2">{{
        $t("role.finance.invoicesIn.filters.table.totalIn")
      }}</span>
      <strong>{{ totalIncoming() }}</strong>

      <div v-if="managePayments()" class="d-inline">
        <span class="ml-2 mr-2">{{
          $t("role.finance.invoicesIn.filters.table.totalPaid")
        }}</span>
        <strong>{{ totalPaid() }}</strong>

        <span class="ml-2 mr-2">{{
          $t("role.finance.invoicesIn.filters.table.totalOpen")
        }}</span>
        <strong>{{ totalToBePaid() }}</strong>
      </div>
    </div>

    <CRow class="mb-2">
      <CCol>
        <CDropdown
          :togglerText="
            $t('role.finance.invoicesIn.filters.table.actionsButton')
          "
          color="primary"
          :disabled="actionsDisabled"
        >
          <CDropdownItem
            v-if="managePayments()"
            @click="markAsPayedSelected(true)"
            >{{
              $t("role.finance.invoicesIn.filters.table.markAllAsPaid")
            }}</CDropdownItem
          >
          <CDropdownItem
            v-if="managePayments()"
            @click="markAsPayedSelected(false)"
            >{{
              $t("role.finance.invoicesIn.filters.table.markAllAsUnpaid")
            }}</CDropdownItem
          >

          <CDropdownItem @click="downloadPdfSelected()">{{
            $t("role.finance.invoicesIn.filters.table.downloadPdf")
          }}</CDropdownItem>
        </CDropdown>
      </CCol>
    </CRow>

    <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
      {{ error.general().join(" ") }}
    </CAlert>

    <div class="table-wrapper">
      <ejs-grid
        ref="grid"
        :dataSource="dm"
        :allowPaging="true"
        :query="query"
        :allowSelection="true"
        :selectionSettings="selectionSettings"
        :pageSettings="pageSettings"
        :dataBound="onDataBound"
        @checkBoxChange="onCheckboxChange"
      >
        <e-columns>
          <e-column type="checkbox" width="40" textAlign="left"></e-column>
          <e-column
            field="id"
            isPrimaryKey="{true}"
            headerText="N"
            :template="numberTemplate"
            width="100"
          ></e-column>
          <e-column
            :headerText="
              $t('role.finance.invoicesIn.filters.table.header.data')
            "
            :template="dateTemplate"
            width="150"
          ></e-column>
          <e-column
            :template="senderTemplate"
            :headerText="
              $t('role.finance.invoicesIn.filters.table.header.sender')
            "
          ></e-column>
          <e-column
            :headerText="
              $t('role.finance.invoicesIn.filters.table.header.numberOfItems')
            "
            :template="productsCountTemplate"
            width="150"
          ></e-column>
          <e-column
            :headerText="
              $t('role.finance.invoicesIn.filters.table.header.amount')
            "
            :template="totalTemplate"
            width="150"
          ></e-column>
          <e-column
            headerText=""
            :template="actionsTemplate"
            width="250"
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";
import last from "lodash/last";
import { Page } from "@syncfusion/ej2-vue-grids";
import { Query } from "@syncfusion/ej2-data";
import { mapGetters } from "vuex";
import {
  addAndPredicateFilter,
  formatDate,
  formatPrice,
  str2bytes,
} from "../../../../helpers/common";
import InvoicesTableNumber from "./InvoicesTableNumber";
import InvoicesTableDate from "./InvoicesTableDate";
import InvoicesTableSender from "./InvoicesTableSender";
import InvoicesTableType from "./InvoicesTableType";
import InvoicesTableProductsCount from "./InvoicesTableProductsCount";
import InvoicesTableTotal from "./InvoicesTableTotal";
import InvoicesInTableActions from "./InvoicesInTableActions";
import { GetDataManagerNew } from "../../../../ds";
import EventBus from "../../../../helpers/EventBus";
import errorResponse from "../../../../helpers/error";
import { saveAs } from "file-saver";

export default {
  name: "InvoicesInTable",

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  provide: {
    grid: [Page],
  },

  inject: ["invoicingSettings"],

  data() {
    const familyId = this.$store.state.role.family_id;
    const dm = GetDataManagerNew("role_invoices_received", [familyId]);

    return {
      error: errorResponse(),
      dm: dm,
      query: this.prepareQuery(this.filters),

      selectionSettings: {
        persistSelection: false,
        enableToggle: true,
        checkboxOnly: true,
      },

      pageSettings: {
        pageSize: 10,
        pageSizes: [10, 20, 50],
      },

      actionsDisabled: true,

      // aggregates
      totAmount: null,
      totAmountPayed: null,
      totAmountNotPayed: null,

      numberTemplate: () => {
        return { template: InvoicesTableNumber };
      },

      dateTemplate: () => {
        return { template: InvoicesTableDate };
      },

      senderTemplate: () => {
        return { template: InvoicesTableSender };
      },

      typeTemplate: () => {
        return { template: InvoicesTableType };
      },

      productsCountTemplate: () => {
        return { template: InvoicesTableProductsCount };
      },

      totalTemplate: () => {
        return { template: InvoicesTableTotal };
      },

      actionsTemplate: () => {
        return { template: InvoicesInTableActions };
      },
    };
  },

  computed: {
    hasAggregatedData() {
      return (
        this.totalAmount !== null &&
        this.totAmountPayed !== null &&
        this.totAmountNotPayed !== null
      );
    },

    ...mapGetters("connections", ["currencySymbol"]),
  },

  mounted() {
    EventBus.$on("invoices:refresh", this.refresh);
  },

  beforeDestroy() {
    EventBus.$off("invoices:refresh", this.refresh);
  },

  methods: {
    onDataBound() {
      try {
        const dataModule = this.$refs.grid.getDataModule();
        const response = JSON.parse(
          get(
            last(dataModule.dataManager.requests),
            "httpRequest.response",
            null
          )
        );
        if (response) {
          this.totalAmount = get(response, "payload.tot_amount", null);
          this.totAmountPayed = get(response, "payload.tot_amount_payed", null);
          this.totAmountNotPayed = get(
            response,
            "payload.tot_amount_not_payed",
            null
          );
        }
      } catch (error) {
        console.error(error);
      }
    },

    onCheckboxChange() {
      const selected = this.$refs.grid.getSelectedRecords();
      this.actionsDisabled = selected.length === 0;
    },

    managePayments() {
      return this.invoicingSettings.manage_payments;
    },

    totalIncoming() {
      return this.totalAmount !== null
        ? formatPrice(this.totalAmount, this.currencySymbol).format()
        : "";
    },
    totalPaid() {
      return this.totalAmountPayed !== null
        ? formatPrice(this.totalAmountPayed, this.currencySymbol).format()
        : "";
    },
    totalToBePaid() {
      return this.totalAmountNotPayed !== null
        ? formatPrice(this.totalAmountNotPayed, this.currencySymbol).format()
        : "";
    },
    refresh() {
      this.query = new Query();
    },

    prepareQuery(params) {
      const search = get(params, "search");
      const start = get(params, "start");
      const end = get(params, "end");

      let predicate = null;

      if (start && end) {
        predicate = addAndPredicateFilter(
          predicate,
          "data_emitted",
          "greaterthan",
          start.toISOString()
        );

        predicate = addAndPredicateFilter(
          predicate,
          "data_emitted",
          "lessthan",
          end.toISOString()
        );
      }

      let query = predicate ? new Query().where(predicate) : new Query();

      const searchFields = ["i_from.name"];
      if (search) {
        query = query.search(search, searchFields);
      }
      return query;
    },

    doSearch(params) {
      this.query = this.prepareQuery(params);
    },

    markAsPayedSelected(payed) {
      const familyId = this.$store.state.role.family_id;

      const selectedIds = this.$refs.grid
        .getSelectedRecords()
        .map((item) => item.id);

      if (selectedIds.length > 0) {
        this.error.reset();
        this.$store
          .dispatch("invoices/paidInvoicesBatch", {
            familyId,
            ids: selectedIds,
            payed: payed,
          })
          .then(() => {
            EventBus.$emit("invoices:refresh");
          })
          .catch((error) => {
            this.error.set(error.response.data.errors);
          });
      }
    },

    downloadPdfSelected() {
      const familyId = this.$store.state.role.family_id;

      const selectedIds = this.$refs.grid
        .getSelectedRecords()
        .map((item) => item.id);

      if (selectedIds.length > 0) {
        this.error.reset();
        this.$store
          .dispatch("invoices/downloadReceivedInvoiceBatch", {
            familyId,
            ids: selectedIds,
          })
          .then((response) => {
            const blob = new Blob([str2bytes(response.data)], {
              type: "application/zip",
            });
            saveAs(blob, `${formatDate(new Date(), "yyyyMMdd")}_invoices.zip`);
          })
          .catch((error) => {
            console.error(error);
            this.error.set(get(error, "response.data.errors"));
          });
      }
    },
  },
};
</script>
