<template>
  <div class="d-flex justify-content-end">
    <div v-if="managePayments()">
      <CButton
        v-if="!isPaid()"
        color="primary"
        size="sm"
        class="mr-2 text-uppercase"
        @click.prevent="onMarkAsPaid"
      >
        {{ INVOICE_ACTIONS_LABEL.MARK_AS_PAID }}
      </CButton>
      <CBadge color="success" v-if="isPaid()" class="mr-2">{{
        $t("role.finance.invoicesIn.filters.tableActions.paid")
      }}</CBadge>
    </div>
    <dropdown-menu :items="actions" :select="onActionSelect" class="ml-auto">
      <font-awesome-icon icon="ellipsis-h"
    /></dropdown-menu>
  </div>
</template>

<script>
import get from "lodash/get";
import {
  INVOICE_ACTIONS,
  gwtIvoiceActionLabel,
} from "../../../../config/global";

export default {
  name: "InvoicesInTableActions",

  inject: ["invoicingSettings"],

  computed: {
    INVOICE_ACTIONS_LABEL() {
      return gwtIvoiceActionLabel(this.$i18n);
    },
    actions() {
      const actions = [
        {
          id: INVOICE_ACTIONS.DOWNLOAD_PDF,
          text: this.INVOICE_ACTIONS_LABEL.DOWNLOAD_PDF,
        },
        {
          id: INVOICE_ACTIONS.DOWNLOAD_CSV,
          text: this.INVOICE_ACTIONS_LABEL.DOWNLOAD_CSV,
        },
      ];
      if (this.managePayments() && this.isPaid()) {
        actions.push({
          id: INVOICE_ACTIONS.CANCEL_PAID,
          text: this.INVOICE_ACTIONS_LABEL.CANCEL_PAID,
        });
      }
      return actions;
    },
  },

  methods: {
    isPaid() {
      return get(this.data, "payed", false); // fixme
    },

    managePayments() {
      return this.invoicingSettings.manage_payments;
    },

    onMarkAsPaid() {
      this.$store.commit("invoices/openPaidModal", {
        invoice: this.data,
      });
    },

    onActionSelect(args) {
      const { id } = args.item;
      const { file_pdf, file_csv } = this.data;

      if (id === INVOICE_ACTIONS.DOWNLOAD_PDF && file_pdf) {
        window.open(file_pdf, "_blank");
      }

      if (id === INVOICE_ACTIONS.DOWNLOAD_CSV && file_csv) {
        window.open(file_csv, "_blank");
      }

      if (id === INVOICE_ACTIONS.CANCEL_PAID) {
        this.$store.commit("invoices/openConfirmModal", {
          action: INVOICE_ACTIONS.CANCEL_PAID,
          invoice: this.data,
        });
      }
    },
  },
};
</script>
